<template>
    <div>
        <v-row justify="center">
            <v-col cols="12">
                <page-toolbar previous-page-label="MTurk Top" title="HITs">
                    <template #contents>
                        <v-btn text color="indigo" to="/console/platform/mturk/hit/create/">
                            <v-icon left>mdi-plus</v-icon>
                            Create HITs
                        </v-btn>
                    </template>
                </page-toolbar>
            </v-col>
            <v-col cols="11">
                <v-data-table
                    dense
                    show-expand
                    sort-desc
                    must-sort
                    :headers="headers"
                    :items="hitBatchItems"
                    :single-expand="false"
                    :expanded.sync="expanded"
                    item-key="hit_type.id"
                    class="elevation-1"
                    :loading="loading"
                    v-model="selectedHITTypes"
                    sort-by="createdAt">

                    <template v-slot:top>
                        <v-card-title>
                            <v-spacer></v-spacer>

                            <v-text-field
                                single-line
                                hide-details 
                                v-model="searchQuery"
                                append-icon="mdi-magnify"
                                label="Search" />

                        </v-card-title>
                        <v-card-text>
                            <div class="text-right">
                                <v-btn
                                    small
                                    outlined
                                    :disabled="loading"
                                    color="indigo"
                                    @click="refreshTable()"
                                >
                                    <v-icon left>mdi-refresh</v-icon>
                                    Refresh table
                                </v-btn>
                            </div>
                        </v-card-text>
                    </template>

                    <template v-slot:item.assignable="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" v-if="statusCounts.HITStatus[item.hit_type.id].Assignable>0" color="success">mdi-circle-medium</v-icon>
                            </template>
                            <span>Assignable</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.name="{ item }">
                        {{ item.name }}
                    </template>
                    <template v-slot:item.projectName="{ item }">
                        {{ item.project_name }}
                    </template>
                    <template v-slot:item.hitsStatus="{ item }">
                        <v-progress-linear
                            striped
                            color="indigo"
                            :value="100-(statusCounts.HITStatus[item.hit_type.id].Reviewable)/statusCounts.all[item.hit_type.id]*100"
                        />
                        <div class="text-center">
                            open: <b>{{ statusCounts.HITStatus[item.hit_type.id].Assignable }}</b>, closed: <b>{{ statusCounts.HITStatus[item.hit_type.id].Reviewable }}</b> (Total <b>{{ statusCounts.all[item.hit_type.id] }}</b> HITs)
                        </div>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ standardDateTimeFormat(item._created_at) }}
                    </template>
                    <template v-slot:item.refresh="{ item }">
                        <v-btn icon :loading="batchProgresses[item.id].refresh.loading" @click="refreshBatch(item);"><v-icon>mdi-refresh</v-icon></v-btn>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div v-if="batchProgresses[item.id].operation.shown">
                                <v-progress-linear :value="(batchProgresses[item.id].operation.counts.success+batchProgresses[item.id].operation.counts.error)/batchProgresses[item.id].operation.counts.all*100" />
                                <div class="text-center font-weight-medium mt-2">
                                    {{ batchProgresses[item.id].operation.type }} HITs:
                                    {{ batchProgresses[item.id].operation.counts.success+batchProgresses[item.id].operation.counts.error }} / {{ batchProgresses[item.id].operation.counts.all }}
                                    (Success: {{ batchProgresses[item.id].operation.counts.success }}, Error: {{ batchProgresses[item.id].operation.counts.error }})
                                </div>
                            </div>

                            <div class="mt-4" align="right">
                                <v-btn small outlined class="ml-2" @click="$refs.expire.show()" color="warning"><v-icon left>mdi-cancel</v-icon>Expire</v-btn>
                                <v-btn small outlined class="ml-2" @click="$refs.delete.show()" color="error"><v-icon left>mdi-trash-can-outline</v-icon>Delete</v-btn>
                            </div>
                            <v-expansion-panels multiple dense :value="[0,1]" class="py-4">
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="py-0">
                                        <p class="text-subtitle-2 ma-0">
                                            HIT Type ({{ item.hit_type.id }})
                                        </p>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="pa-0">
                                        <v-simple-table dense>
                                            <tbody>
                                                <tr v-if="credentials">
                                                    <th width="250">HIT Group URL</th>
                                                    <td><a :href="hitGroupURL(item.hits[0].HITGroupId, credentials.is_sandbox)" target="_blank">{{ hitGroupURL(item.hits[0].HITGroupId, credentials.is_sandbox) }}</a> <v-icon small>mdi-open-in-new</v-icon></td>
                                                </tr>
                                                <tr v-for="key in ['Title', 'Description', 'Keywords', 'Reward']" :key="`expansion-HITType-${item.hit_type.id}-${key}`">
                                                    <th>{{ key }}</th>
                                                    <td>
                                                        <div v-if="key==='Keywords' && item.hit_type[key]!==''">
                                                            <v-chip small v-for="keyword in item.hit_type[key].split(',')" :key="`keyword-tooltip-${item.hit_type.id}-${keyword}`">
                                                                {{ keyword }}
                                                            </v-chip>
                                                        </div>
                                                        <div v-else-if="key==='Reward'">${{ item.hit_type[key] }}</div>
                                                        <div v-else>{{ item.hit_type[key] }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>AssignmentDurationInSeconds</th>
                                                    <td>{{ item.hit_type.AssignmentDurationInSeconds }} ({{ secondsToFormattedTime(item.hit_type.AssignmentDurationInSeconds, ['hrs', 'mins', 'secs']) }})</td>
                                                </tr>
                                                <tr>
                                                    <th>AutoApprovalDelayInSeconds</th>
                                                    <td>{{ item.hit_type.AutoApprovalDelayInSeconds }} ({{ secondsToFormattedTime(item.hit_type.AutoApprovalDelayInSeconds, ['wks', 'days', 'hrs', 'mins', 'secs']) }})</td>
                                                </tr>
                                                <tr v-for="requirement,i in dropTuttiHITBatchQualTypes(item.hit_type.QualificationRequirements)" :key="`${item.hit_type.id}-QualificationRequirement${i}`">
                                                    <th>QualificationRequirement {{ i+1 }}</th>
                                                    <td>
                                                        <v-simple-table dense>
                                                            <tbody>
                                                                <tr>
                                                                    <th>QualificationTypeId</th>
                                                                    <td>{{ requirement.QualificationTypeId }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Comparator</th>
                                                                    <td>{{ requirement.Comparator }}</td>
                                                                </tr>
                                                                <tr v-if="'IntegerValues' in requirement">
                                                                    <th>IntegerValues</th>
                                                                    <td>{{ requirement.IntegerValues.join(', ') }}</td>
                                                                </tr>
                                                                <tr v-if="'LocaleValues' in requirement">
                                                                    <th>LocaleValues</th>
                                                                    <td>{{ requirement.LocaleValues.map((l) => Object.entries(l).map((_l) => (_l[1])).join('-')).join(', ') }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>ActionsGuarded</th>
                                                                    <td>{{ requirement.ActionsGuarded }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </v-simple-table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="py-0">
                                        <p class="text-subtitle-2 ma-0">HITs ({{ item.hits.length }})</p>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hits-chart :hits="item.hits" :height="60" :key="`batch-chart-${item.id}-${batchProgresses[item.id].refresh.count}`" />
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </td>
                        <tutti-dialog-alert
                            ref="expire"
                            @confirm="expireHITs(item);"
                        >
                            <template #title>
                                Expire HITs in HIT Group
                            </template>
                            <template #body>
                                <v-card-text>
                                    Do you really wish to expire all HITs in this HIT Group?
                                </v-card-text>
                            </template>
                        </tutti-dialog-alert>
                        <tutti-dialog-alert
                            ref="delete"
                            @confirm="deleteHITs(item);"
                        >
                            <template #title>
                                Delete HITs in HIT Group
                            </template>
                            <template #body>
                                <v-card-text>
                                    Do you really wish to delete all HITs in this HIT Group?
                                </v-card-text>
                            </template>
                        </tutti-dialog-alert>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <tutti-snackbar ref="snackbar" />
    </div>
</template>
<script>
import { onMTurkCredentialsUpdate } from '@/lib/mturk'
import { secondsToFormattedTime, standardDateTimeFormat } from '@/lib/utils'
import HITsChart from './HITsChart'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import PageToolbar from '@/components/ui/PageToolbar'

export default {
    components: {
        PageToolbar,
        TuttiDialogAlert,
        TuttiSnackbar,
        'hits-chart': HITsChart,
    },
    data: () => ({
        standardDateTimeFormat,

        expanded: [],
        selectedHITTypes: [],
        searchQuery: "",
        headers: [
            { text: '', value: 'assignable' },
            { text: 'Name', value: 'name' },
            { text: 'Project', value: 'projectName' },
            { text: 'HITs Status', value: 'hitsStatus' },
            { text: 'Creation Time', value: 'createdAt' },
            { text: '', value: 'refresh' },
            { text: '', value: 'data-table-expand' },
        ],
        hitBatchItems: [],
        loading: false,
        statusCounts: {
            HITStatus: {},
            HITReviewStatus: {},
            all: {},
        },
        batchProgresses: {},
        customQualTypes: []
    }),
    props: ["duct", 'projectName', "name", 'client', 'credentials'],

    computed: {
        selectedHITIds() {
            var hitIds = [];
            for(var i in this.selectedHITTypes){
                hitIds = [...hitIds, ...this.selectedHITTypes[i]["detail"]["HITIds"]];
            }
            return hitIds;
        }
    },
    methods: {
        secondsToFormattedTime,
        hitGroupURL(groupId, isSandbox) {
            const subdomain = isSandbox ? "workersandbox" : "worker";
            return `https://${subdomain}.mturk.com/projects/${groupId}/tasks`;
        },
        secondsToTimeString(seconds) {
            var hours = Math.floor(seconds / 3600);
            seconds -= hours*3600;
            var minutes = Math.floor(seconds / 60);
            seconds -= minutes*60;
            return `${hours}:${("00"+minutes).slice(-2)}:${("00"+seconds).slice(-2)}`;
        },
        listHITs(HITTypeId, Cached){
            if(!HITTypeId) this.loading = true;
            this.duct.controllers.mturk.listHITsForHITType(HITTypeId, Cached);
        },
        expireHITs(batch) {
            const activeHITIds = batch.hits.filter((h) => (h.HITStatus=='Assignable')).map((h) => (h.HITId));
            if(activeHITIds.length > 0){
                this.client.mturk.expireHITs.send({ hit_ids: activeHITIds, request_id: batch.id });
                this.batchProgresses[batch.id].operation.counts = { success: 0, error: 0, all: activeHITIds.length };
                this.batchProgresses[batch.id].operation.type = 'Expire';
                this.batchProgresses[batch.id].operation.shown = true;
            }
        },
        deleteHITs(batch) {
            const hitIds = batch.hits.map((h) => (h.HITId));
            this.client.mturk.deleteTuttiHITBatch.send({ request_id: batch.id, batch_id: batch.id });
            this.batchProgresses[batch.id].operation.counts = { success: 0, error: 0, all: hitIds.length };
            this.batchProgresses[batch.id].operation.type = 'Delete';
            this.batchProgresses[batch.id].operation.shown = true;
        },
        dropTuttiHITBatchQualTypes(qts) {
            const customQualTypeIds = this.customQualTypes.map((qt) => (qt.QualificationTypeId));
            return qts.filter((qt) => (customQualTypeIds.includes(qt.QualificationTypeId)));
        },
        refreshBatch(batch) {
            this.batchProgresses[batch.id].refresh.loading = true;
            this.client.mturk.listHITsForTuttiHITBatch.send({ batch_id: batch.id, cached: false });
        },
        refreshTable() {
            this.loading = true;
            this.hitBatchItems = [];
            this.client.mturk.listTuttiHITBatchesWithHITs.send();
        }
    },

    mounted() {
        this.client.invokeOnOpen(async () => {
            this.customQualTypes = await this.client.mturk.listQualificationTypes();
            const showSnackbarForRequestId = (requestId) => {
                    const { counts, type: operation } = this.batchProgresses[requestId].operation;
                    if(counts.success===counts.all) {
                        this.$refs.snackbar.show(
                            'success',
                            `Successfully ${operation.toLowerCase()}d ${counts.all} HITs`
                        );
                    } else if(counts.success===0) {
                        this.$refs.snackbar.show(
                            'error',
                            `Failed in ${operation.slice(0,-1)}ing ${counts.all} HITs`
                        );
                    } else {
                        this.$refs.snackbar.show(
                            'warning',
                            `${operation}d ${counts.success} HITs, while failed for ${counts.error} HITs`
                        );
                    }
                };
            this.client.mturk.on('expireHITs', {
                success: (data) => {
                    const requestId = data.request_id;
                    if(data.counts) {
                        this.batchProgresses[requestId].operation.counts = data.counts;
                    } else {
                        showSnackbarForRequestId(requestId);
                        this.client.mturk.listHITsForTuttiHITBatch.send({ batch_id: requestId, cached: false });
                    }
                }
            });
            this.client.mturk.on('deleteTuttiHITBatch', {
                success: (data) => {
                    const requestId = data.request_id;
                    if(data.counts) {
                        this.batchProgresses[requestId].operation.counts = data.counts;
                    } else {
                        showSnackbarForRequestId(requestId);
                        const oldIdx = this.hitBatchItems.findIndex((batch) => (batch.id===requestId));
                        this.hitBatchItems.splice(oldIdx,1);
                    }
                }
            });
            this.client.mturk.on(['listTuttiHITBatchesWithHITs', 'listHITsForTuttiHITBatch'], {
                success: (data) => {
                    if(data) {
                        const hitTypeId = data.hit_type.id;
                        this.statusCounts.HITStatus[hitTypeId] = 
                            {
                                'Assignable': 0,
                                'Unassignable': 0,
                                'Reviewable': 0,
                                'Reviewing': 0,
                                'Disposed': 0
                            };
                        this.statusCounts.HITReviewStatus[hitTypeId] = 
                            {
                                'NotReviewed': 0,
                                'MarkedForReview': 0,
                                'ReviewedAppropriate': 0,
                                'ReviewedInappropriate': 0
                            };
                        this.statusCounts.all[hitTypeId] = data.hits.length;
                        data.hits.forEach((d) => {
                            this.statusCounts.HITStatus[hitTypeId][d.HITStatus]++;
                            this.statusCounts.HITReviewStatus[hitTypeId][d.HITReviewStatus]++;
                        });
                        if(!(data.id in this.batchProgresses)) {
                            this.$set(this.batchProgresses, data.id, {
                                operation: {
                                    counts: {},
                                    type: '',
                                    shown: false,
                                },
                                refresh: {
                                    loading: false,
                                    count: 0,
                                }
                            });
                        }
                        data.hits = data.hits.sort((a,b) => (a.CreationTime>b.CreationTime ? 1 : -1));
                        const oldIdx = this.hitBatchItems.findIndex((batch) => (batch.id===data.id));
                        if(oldIdx > -1) {
                            this.hitBatchItems.splice(oldIdx, 1, data);
                            this.batchProgresses[data.id].refresh.count++;
                        } else {
                            this.hitBatchItems.unshift(data);
                        }
                        this.batchProgresses[data.id].refresh.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
            });
            onMTurkCredentialsUpdate(this, () => {
                this.refreshTable();
            });
        });
    }
}
</script>
<style scoped>
.v-expansion-panel-header {
    min-height: 35px!important;
}
.v-expansion-panel-content__wrap {
    padding-bottom: 8px!important;
}
.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f5f5f5;
}
.is-root, .is-root div {
    font-size: 9pt;
}
</style>
